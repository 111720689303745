<template>
<div class="header">
 <div class="logo">
   <router-link to="/">
     <img src="@/assets/img/logo.png">
   </router-link>

 </div>
  <div class="search">
    <input class="search-input" type="text" v-model="keyword" @keydown.enter="search" ref="searchInput">
     <img src="@/assets/img/search.png"  class="search-btn" @click="search()">
  </div>
  <div class="bag">
    <router-link to="/cart">
    <div class="bag-count">
      {{ count }}
    </div>
    <img src="@/assets/img/bag.png" class="bag-img">
    </router-link>
  </div>
</div>
  <div class="block"></div>

</template>

<script>
import {getSearch} from "@/network/main";
export default {
  name: "Header",
  data() {
    return {
      count: '',
      keyword:'',
    };
  },
  created() {
    let selectedIdsLength = localStorage.getItem('selectedIdsLength');
    if (selectedIdsLength) {
      this.count = parseInt(selectedIdsLength);
    } else {
      this.count = 0;
    }
    // 监听自定义事件
    window.addEventListener('selectedIdsLengthUpdated', this.handleSelectedIdsLengthUpdated);
  },
  mounted() {
    window.addEventListener('keydown', this.searchHandleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('selectedIdsLengthUpdated', this.handleSelectedIdsLengthUpdated);
    window.removeEventListener('keydown', this.searchHandleKeyDown);
  },
  methods: {
    handleSelectedIdsLengthUpdated(event) {
      this.count = event.detail;
    },
    //键盘enter
    searchHandleKeyDown(event) {
      if (event.keyCode === 13 && document.activeElement === this.$refs.searchInput) {
        this.search();
      }
    },
    //搜索
    search() {
      getSearch(this.keyword).then((res) => {
        if (res.data.code === 200) {
          const resultString = JSON.stringify(res.data.data.data);
          this.$router.push({
            name: 'searchData',
            query: {
              keyword: this.keyword,
              result: resultString
            }
          });
          console.log(res.data.data.data);
          this.keyword = ''; // 清空
        }
      });
    }
  }
};
</script>

<style scoped>
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 100px;
  box-shadow: 6px 6px 20px #f1f1f1;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1;
}
.block{
  height: 80px;
}
.logo img{
  height: 56px;
}
.search{
  width: 500px;
  height: 40px;
  background:#F2F2F2;
  border-radius: 30px;
  position: relative;
}
.search-input{
  width: calc(100% - 36px);
  height: 40px;
  border: none;
  background: transparent;
  padding: 0 20px;
  font-size: 16px;
}
.search-btn{
  position: absolute;
  top: 10px;
  right: 15px;
  height: 18px;
  cursor: pointer;
}
.bag{
  position: relative;
}
.bag-img{
  width: 26px;
}
.bag-count{
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: var(--color-tint);
  font-size: 12px;
  text-align: center;
  line-height: 15px;
  color: var(--color-high-text);
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width:0px) and (max-width:1024px){
  .header{
    padding: 10px 15px;
  }
}
@media only screen and (min-width:0px) and (max-width:767px){
  .block{
    height: 70px;
  }
  .logo img{
    height: 46px;
  }
  .search{
    width: 240px;
  }
}

</style>