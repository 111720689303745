import axios from 'axios'

// 获取指定名称的cookie值
function getCookie(name) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");
        if (cookie[0] === name) {
            return cookie[1];
        }
    }
    return null;
}

export function request(config) {
    // 创建axios的实例
    const instance = axios.create({
        baseURL: 'https://api.seasonsusainc.com/api/',
    })

    // 请求拦截
    instance.interceptors.request.use(config => {
        config.headers = {
            'X-Requested-With': 'XMLHttpRequest'
        }

        if (config.needPassword) { // 判断是否需要密码
            const password = getCookie('password');
            if (password) {
                config.headers['Access-Password'] = password;
            } else {
                console.warn('Password not found!');
            }
        }

        return config
    }, err => {
        console.log(err)
    });

    // 响应拦截
    instance.interceptors.response.use(res => {
        return res
    }, err => {
        console.log(err)
    });

    // 发送网络请求
    return instance(config);
}

