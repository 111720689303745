<template>
  <div>
    <Header v-if="showHeader"/>
    <keep-alive>
      <router-view :key="$route.fullPath" v-if="$route.meta.keepalive"/>
    </keep-alive>
    <router-view :key="$route.fullPath" v-if="!$route.meta.keepalive"/>
    <Footer v-if="showFooter"/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  computed: {
    showHeader() {
      return this.$route.meta.showHeader !== false; // 如果showHeader字段不存在或为true，则返回true，否则返回false
    },
    showFooter() {
      return this.$route.meta.showFooter !== false; // 如果showHeader字段不存在或为true，则返回true，否则返回false
    },
  }

};
</script>

<style>
@import "assets/css/main.css";
</style>
