import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


createApp(App).use(router).use(VueViewer).mount('#app')

//清空缓存
window.addEventListener('beforeunload', () => {
    localStorage.clear();
});



